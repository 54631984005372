<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5 7.5C20.3284 7.5 21 6.82843 21 6C21 5.17157 20.3284 4.5 19.5 4.5C18.6716 4.5 18 5.17157 18 6C18 6.82843 18.6716 7.5 19.5 7.5ZM4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7L15 7C15.5523 7 16 6.55229 16 6C16 5.44772 15.5523 5 15 5L4 5ZM4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13L15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11L4 11ZM3 18C3 17.4477 3.44772 17 4 17L15 17C15.5523 17 16 17.4477 16 18C16 18.5523 15.5523 19 15 19L4 19C3.44772 19 3 18.5523 3 18ZM21 12C21 12.8284 20.3284 13.5 19.5 13.5C18.6716 13.5 18 12.8284 18 12C18 11.1716 18.6716 10.5 19.5 10.5C20.3284 10.5 21 11.1716 21 12ZM19.5 19.5C20.3284 19.5 21 18.8284 21 18C21 17.1716 20.3284 16.5 19.5 16.5C18.6716 16.5 18 17.1716 18 18C18 18.8284 18.6716 19.5 19.5 19.5Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
