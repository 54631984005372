<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.8705 7.4921C22.1423 7.01134 21.9729 6.40125 21.4922 6.12943C21.0114 5.85761 20.4013 6.027 20.1295 6.50776L15.2308 15.1721L12.8017 11.914C12.4716 11.4713 11.8451 11.3799 11.4023 11.71C10.9595 12.0401 10.8682 12.6667 11.1983 13.1095L14.4527 17.4746C14.9278 18.1118 15.901 18.0504 16.2922 17.3585L21.8705 7.4921ZM3 5.99997C2.44772 5.99997 2 6.44768 2 6.99997C2 7.55225 2.44772 7.99997 3 7.99997L14 7.99997C14.5523 7.99997 15 7.55226 15 6.99997C15 6.44769 14.5523 5.99997 14 5.99997L3 5.99997ZM3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13L7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11L3 11ZM2 17C2 16.4477 2.44772 16 3 16L9 16C9.55228 16 10 16.4477 10 17C10 17.5523 9.55228 18 9 18L3 18C2.44772 18 2 17.5523 2 17Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
