<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="6" r="1.25" fill="currentColor" stroke="currentColor" stroke-width="0.5" />
    <circle cx="12" cy="12" r="1.25" fill="currentColor" stroke="currentColor" stroke-width="0.5" />
    <circle cx="12" cy="18" r="1.25" fill="currentColor" stroke="currentColor" stroke-width="0.5" />
  </svg>
</template>
<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
