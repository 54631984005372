<template>
  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.66684 0.333496C0.952927 0.333496 0.282744 0.93013 0.375501 1.74188C0.602973 3.73255 1.70914 5.20113 2.71326 6.15098C3.0411 6.4611 3.36274 6.72053 3.6479 6.9297C3.36016 7.1071 3.0392 7.3325 2.71321 7.6134C1.66242 8.51887 0.561114 10.0027 0.364367 12.2746C0.295746 13.067 0.945019 13.6668 1.66684 13.6668H8.33351C9.05534 13.6668 9.70461 13.067 9.63599 12.2746C9.43924 10.0027 8.33793 8.51887 7.28715 7.6134C6.96116 7.3325 6.6402 7.1071 6.35245 6.9297C6.63762 6.72053 6.95925 6.4611 7.2871 6.15098C8.29122 5.20113 9.39738 3.73255 9.62485 1.74188C9.71761 0.93013 9.04743 0.333496 8.33351 0.333496H1.66684ZM4.73518 7.84554C4.83733 7.79291 4.92692 7.75081 5.00018 7.71833C5.07343 7.75081 5.16302 7.79291 5.26517 7.84554C5.57355 8.00441 5.99044 8.2561 6.41678 8.62347C7.25389 9.34481 8.12905 10.5105 8.30252 12.3335H1.69783C1.8713 10.5105 2.74646 9.34481 3.58358 8.62347C4.00992 8.2561 4.4268 8.00441 4.73518 7.84554ZM4.80318 6.1089C4.87529 6.15608 4.94137 6.19763 5.00018 6.23356C5.05899 6.19763 5.12507 6.15608 5.19718 6.1089C5.5117 5.90314 5.93592 5.59376 6.37082 5.18236C7.23199 4.36774 8.09272 3.19483 8.29083 1.66683H1.70952C1.90763 3.19483 2.76836 4.36774 3.62953 5.18236C4.06443 5.59376 4.48866 5.90314 4.80318 6.1089ZM4.4796 3.28225C4.74648 3.61586 5.25387 3.61586 5.52076 3.28225L5.81293 2.91703C6.04294 2.62952 6.46247 2.58291 6.74997 2.81292C7.03748 3.04292 7.0841 3.46245 6.85409 3.74996L6.56192 4.11518C5.76126 5.11599 4.23909 5.11599 3.43844 4.11518L3.14627 3.74996C2.91626 3.46245 2.96287 3.04292 3.25038 2.81292C3.53789 2.58291 3.95742 2.62952 4.18742 2.91703L4.4796 3.28225Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
  },
};
</script>
