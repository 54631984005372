<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 9V9.5H11H13H13.5V9V7V6.5H13H11H10.5V7V9ZM10.5 17V17.5H11H13H13.5V17V11V10.5H13H11H10.5V11V17ZM21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12Z"
      :fill="fill"
      :stroke="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
    fill: { type: String, required: false, default: 'currentColor' },
  },
};
</script>
