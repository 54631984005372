<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM13 11V16H11V11H13ZM13 10V8H11V10H13Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
    fill: { type: String, required: false, default: 'currentColor' },
  },
};
</script>
